
import Mixins from "@/Mixins.vue"
import _ from "lodash"
import { Component } from "vue-property-decorator"
import BookCover from "../components/BookCover.vue"
import DialogComponent from "../components/DialogComponent.vue"
import LogoImg from "../components/LogoImg.vue"
import ProductDetails from "../components/ProductDetails.vue"
import ProgressBar from "../components/ProgressBar.vue"
import TimerComponent from "../components/TimerComponent.vue"
import { invoiceAlunoStore, invoiceStore } from "../store"
import Cartao from "./PaymentForms/Cartao.vue"
import Coupon from "./PaymentForms/Coupon.vue"
import Pix from "./PaymentForms/Pix.vue"
import PrivacyData from "./PrivacyData.vue"

@Component({
  components: {
    LogoImg,
    Cartao,
    Pix,
    DialogComponent,
    Coupon,
    BookCover,
    ProgressBar,
    ProductDetails,
    TimerComponent,
    PrivacyData,
  },
})
export default class Payments extends Mixins {
  items: any[] = []

  currentTab = 0
  couponCode = ""
  confirmBtn = false
  openDialog2 = false
  openDialog = false

  clickedClipboardCopy = false
  loadingFormasPagamento = false

  aceites = {
    TermoUso: false,
    PoliticaPrivacidade: false,
  }

  paymentOptions: any = {
    "1": "Cartão de Crédito",
    "8": "Outros",
    "11": "PIX",
  }

  selectedFormPayment = null

  headers = [
    { width: 50, text: "", value: "product.capa", align: "center" },
    { text: "Produto", value: "product.item_name", align: "center" },
    { text: "Quantidade", value: "quantity", align: "center" },
  ]

  get formasPagamento() {
    return invoiceStore.formasPagamento
  }

  get invoiceAluno() {
    return invoiceAlunoStore.invoiceAluno
  }

  get fields() {
    if (!this.invoiceAluno) return

    const items = _.get(this.lastInvoice, "items", [])
    const fields = {
      Nome: this.invoiceAluno.full_name,
      CPF: this.formatCPF(this.invoiceAluno.cpf),
      Unidade: _.get(this.lastInvoice.company, "nome_fantasia"),
      Endereço: `${this.invoiceAluno.street}, ${this.invoiceAluno.street_number} - Bairro ${this.invoiceAluno.neighborhood}`,
      Telefone: this.formatPhone(this.invoiceAluno.phone),
      "Itens do pedido": _.join(
        _.map(
          items,
          (i) =>
            `${String(i.quantity).padStart(2, "0")} - ${i.product.item_name}`
        ),
        ", "
      ),
    }
    return fields
  }

  get lastInvoice() {
    return invoiceStore.lastInvoice
  }

  get loggedEmail() {
    return localStorage.getItem("invoice-login")
  }

  async loadFormasPagamentoCoupon({ confirmed, couponCode }) {
    if (confirmed) {
      this.couponCode = couponCode

      const { confirmed, data } = await invoiceStore.getFormasPagamento({
        preInvoiceId: this.lastInvoice.id!,
        couponCode: couponCode,
      })

      if (confirmed) {
        this.$swal({
          title: "Cupom aplicado!",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          returnFocus: true,
        })
      } else {
        this.$swal({
          title: "Erro ao aplicar cupom",
          text: data,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          returnFocus: true,
        })
      }
    }
  }

  // onChangeTab(tab: number) {
  //   if (tab === 1 && this.invoiceAluno?.company?.franchising_id === 1) {
  //     this.$swal({
  //       icon: "error",
  //       title: "Atenção!",
  //       html: `
  //         <b>No momento, o pagamento via PIX está
  //         temporariamente indisponível. Nossa equipe
  //         já está trabalhando para resolver o problema,
  //         e em breve essa opção estará disponível
  //         novamente.</b>
  //         <br />
  //         <br />
  //         Enquanto isso, você pode seguir com suas compras
  //         utilizando o cartão de crédito normalmente.
  //         <br />
  //         <br />
  //         Agradecemos pela compreensão.
  //       `,
  //       showCancelButton: false,
  //       timer: undefined,
  //       timerProgressBar: undefined,
  //     });
  //   }
  // }

  continueBtn() {
    this.openDialog = false
    this.openDialog2 = true
  }

  async clipboardCopy(value: string) {
    // Altera URL sem redirect
    history.pushState({}, null, value)

    if (!!navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(value)
    } else {
      let textArea = document.createElement("textarea")
      textArea.value = value
      textArea.style.position = "fixed"
      // textArea.style.left = "-999999px";
      // textArea.style.top = "-999999px";
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      await new Promise<void>((res, rej) => {
        // here the magic happens
        document.execCommand("copy") ? res() : rej()
        textArea.remove()
      })
    }

    this.$swal({
      icon: "success",
      text: "Link copiado para a área de transferência!",
      timer: 2000,
      timerProgressBar: true,
    })
  }

  async mounted() {
    if (!this.lastInvoice || !this.lastInvoice.id) {
      this.closeSection()
      this.$swal({
        icon: "error",
        title: "Pedido não encontrado",
        text: `
          Não conseguimos localizar nenhum pedido para ${this.invoiceAluno.full_name}
        `,
      })
    } else {
      if (this.lastInvoice.status_id === 1) {
        this.openDialog = true
      }

      this.loadingFormasPagamento = true
      await invoiceStore.getFormasPagamento({
        preInvoiceId: this.lastInvoice.id,
        // couponCode: "CLUB12345", // DEBUG TEST
      })
      this.loadingFormasPagamento = false
      this.items = this.lastInvoice.items
      if (this.lastInvoice.status_id === 1) {
        // this.openDialog = true;
      }
    }
  }
}
